import { render, staticRenderFns } from "./RegistrationCopy.vue?vue&type=template&id=0b69af90&scoped=true&"
var script = {}
import style0 from "./RegistrationCopy.vue?vue&type=style&index=0&id=0b69af90&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b69af90",
  null
  
)

export default component.exports